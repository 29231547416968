<template>
  <admin>
    <metatag title="Download Reports"></metatag>
    <page-header></page-header>
    <section>
      <v-card class="mb-4">
        <v-card-title>
          {{ "Generate & download reports" }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="4">
                <ReportType />
              </v-col>
            </v-row>
            <p class="mb-2">Filter by dealers</p>
            <v-row>
              <v-col v-if="user.is_admin" cols="4">
                <Dealer
                  :disableDealer="isDealerDisable"
                  @update:dealer="updateDealer"
                />
              </v-col>
              <v-col v-if="user.is_admin" cols="4">
                <v-select
                  append-icon="mdi-chevron-down"
                  :items="dealerZones"
                  item-text="text"
                  item-value="value"
                  label="Dealer Zone"
                  outlined
                  clearable
                  hide-details
                  clear-icon="mdi-close-circle-outline"
                  background-color="selects"
                  v-model="dealerZone"
                  @change="updateDealerZone"
                  :disabled="isDealerZoneDisable"
                ></v-select>
              </v-col>
              <v-col v-if="user.is_admin" cols="4">
                <v-select
                  append-icon="mdi-chevron-down"
                  :items="dealerGroups"
                  item-text="text"
                  item-value="value"
                  label="Dealer Group"
                  outlined
                  clearable
                  hide-details
                  clear-icon="mdi-close-circle-outline"
                  background-color="selects"
                  v-model="dealerGroup"
                  :disabled="isDealerGroupDisable"
                  @change="updateDealerGroup"
                ></v-select>
              </v-col>
            </v-row>
            <br />
            <p class="mb-2">Filter by date</p>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  type="date"
                  label="Start Date"
                  class="dt-text-field"
                  outlined
                  v-model="startDate"
                  :rules="rules.startDate"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="date"
                  label="End Date"
                  class="dt-text-field"
                  outlined
                  v-model="endDate"
                  :rules="rules.endDate"
                  :max="dateToday"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <br />
                <v-btn large :disabled="loading" :loading="loading" exact color="primary" @click="downloadReport">
                  <v-icon left>mdi-download-box-outline</v-icon>
                  {{ "Download" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </section>
  </admin>
</template>

<script>
import ReportType from "@/components/ReportType/ReportType";
import Dealer from "@/components/Dealer/Dealer";
import DealerGroup from "@/components/DealerGroup/DealerGroup";
import DateRange from "@/components/DateRange/DateRange";

import apiReportService from "@/services/api/modules/reportService";
import { mapGetters, mapActions } from "vuex";
import * as helpers from "@/core/helpers";

export default {
  data() {
    return {
      loading: false,
      valid: true,
      date: null,
      user: null,
      modal: false,
      dealerZone: "",
      dealerGroup: "",
      isDealerZoneDisable: false,
      isDealerGroupDisable: false,
      isDealerDisable: false,
      startDate: "",
      endDate: "",
      dateToday: this.formatDate(
        new Date(Date.now() + 3600 * 1000 * 24),
        "YYYY-MM-DD"
      ),
      rules: {
        startDate: [(value) => !!value || "Start date is required"],
        endDate: [(value) => !!value || "End date is required"],
      },
    };
  },
  components: {
    ReportType,
    Dealer,
    DateRange,
  },
  computed: {
    ...mapGetters({
      reportType: "reportType/GET_REPORT_TYPE",
      dealerZones: "dealerZone/GET_DEALER_ZONES",
      dealerGroups: "dealerGroup/GET_DEALER_GROUPS",
      dealer: "dealer/GET_DEALER",
    }),
  },
  async mounted() {
    await this.getDealerZones();
    await this.getDealerGroups();
  },
  created() {
    if (!localStorage.user) {
      this.$router.push({ name: "login" });
    } else {
      this.user = JSON.parse(localStorage.user);
    }
  },
  methods: {
    ...mapActions({
      alertBox: "snackbar/show",
      getDealerZones: "dealerZone/getDealerZones",
      getDealerGroups: "dealerGroup/getDealerGroups",
    }),
    showTip(e) {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },
    formatDate(item, format) {
      return helpers.format_date(item, format);
    },
    setDateRange(dateRange) {
      this.date = dateRange;
    },
    async downloadReport() {
      const isValid = this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      let dates = [];

      if (!this.reportType) {
        this.alertBox({
          text: "Report type is required.",
        });
        return false;
      }

      if (!this.startDate && !this.endDate) {
        this.alertBox({
          text: "Start and end date are required.",
        });
        return false;
      }

      this.loading = true;

      dates = [this.startDate, this.endDate];

      var filter = {
        name: this.reportType,
        dealer_id: this.dealer,
        dealer_zone_id: this.dealerZone,
        dealer_group_id: this.dealerGroup,
        dateRange: dates,
      };

      const { status, data } = await apiReportService.download(filter);

      this.loading = false;

      if (status != 200) {
        this.alertBox(
          {
            text: data.message,
            timeout: 10000,
          },
          {
            root: true,
          }
        );

        return;
      }

      this.downloadFile(data);
    },
    updateDealerZone() {
      this.isDealerGroupDisable = true;
      this.isDealerDisable = true;
      if (this.dealerZone == "" || this.dealerZone == null) {
        this.dealerGroup = "";
        this.isDealerGroupDisable = false;
        this.isDealerDisable = false;
      }
    },
    updateDealerGroup() {
      this.isDealerZoneDisable = true;
      this.isDealerDisable = true;
      if (this.dealerGroup == "" || this.dealerGroup == null) {
        this.dealerZone = "";
        this.isDealerZoneDisable = false;
        this.isDealerDisable = false;
      }
    },
    updateDealer() {
      this.isDealerZoneDisable = true;
      this.isDealerGroupDisable = true;
      if (this.dealer.length == 0 || this.dealer == null) {
        this.isDealerZoneDisable = false;
        this.isDealerGroupDisable = false;
      }
    },
    downloadFile(data) {
      var fURL = document.createElement("a");
      fURL.href = data.data.downloadURL;
      document.body.appendChild(fURL);
      fURL.click();

      setTimeout(() => {
        apiReportService.delete(data.data.deleteURL);
      }, 300);
    },
  },
};
</script>

<style></style>
