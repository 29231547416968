import api from "@/services/api/baseService";

class apiReportService {
  download(filter) {
    return api({
      method: "post",
      url: "/reports/download",
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: filter,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }

  delete(URL) {
    return api({
      method: "POST",
      url: URL,
      headers: {
        // Authorization: `Bearer ${localStorage.token}`,
      },
      data: null,
    })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiReportService();
